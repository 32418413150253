<template>
  <div class="page-div">
    <page-navigation-bar title="收货地址" />
    <div class="address-item-div" v-if="addressNumber > 0">
      <div class="addressee-div">
        <label class="addressee-label">{{ addressInfo.addressee }}</label>
        <label>{{ addressInfo.phone }}</label>
      </div>
      <div class="other-item-div">
        <label>{{ addressInfo.address }}</label>
      </div>
      <div class="other-item-div btn-div">
        <input class="del-btn" type="button" value="删除" @click="onShowConfirmBox">
        <input class="edit-btn" type="button" value="编辑" @click="onToEditAddress">
      </div>
    </div>
    <div class="empty-data-div" v-else>
      <div class="empty-data-img-div">
        <img src="../assets/empty-data.png" alt=""/>
      </div>
      <div class="empty-tips-div">
        <label>您还没有添加地址</label>
      </div>
      <div>
        <input class="add-btn" type="button" value="添加地址" @click="onToAddAddress">
      </div>
    </div>
    <div class="confirm-div" v-if="isShowConfirmBox" @click="onCloseConfirmBox">
      <div class="confirm-box-div">
        <div class="title-div">温馨提示</div>
        <div class="content-div">请确认是否删除收货地址</div>
        <div class="btn-div">
          <input class="ok-btn" type="button" value="确定" @click.stop="onDelAddress">
          <input class="cancel-btn" type="button" value="取消" @click.stop="onCloseConfirmBox">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageNavigationBar from "../components/PageNavigationBar";
import { getAddress, postAddressDel } from "../common/api";

export default {
  name: "Addresses",
  components: { PageNavigationBar },
  data() {
    return {
      addressInfo: {
        id: "",
        addressee: "",
        phone: "",
        address: ""
      },
      addressNumber: 0,
      isShowConfirmBox: false
    }
  },
  created() {
    this.doGetAddress();
  },
  methods: {
    doGetAddress() {
      getAddress().then(data => {
        if(data === undefined || data == null){
          this.addressNumber = 0;
        }else{
          this.addressInfo.id = data.id;
          this.addressInfo.addressee = data.name;
          this.addressInfo.phone = data.phone;
          this.addressInfo.address = data.address;
          this.addressNumber = 1;
        }
      }).catch(error => {
        this.$router.back();
        if(Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")){
          this.$layer.msg(error.data.msg);
        }else{
          this.$layer.msg("查询收货地址失败，请稍后重试");
        }
      });
    },
    onToAddAddress() {
      this.$router.push({ name: "AddressAdd" });
    },
    onToEditAddress() {
      this.$router.push({ name: "AddressEdit" });
    },
    onShowConfirmBox() {
      this.isShowConfirmBox = true;
    },
    onCloseConfirmBox() {
      this.isShowConfirmBox = false;
    },
    onDelAddress() {
      postAddressDel({id: this.addressInfo.id}).then(data => {
        this.addressNumber = 0;
        this.addressInfo.id = "";
        this.addressInfo.addressee = "";
        this.addressInfo.phone = "";
        this.addressInfo.address = "";
        this.isShowConfirmBox = false;
      }).catch(error => {
        this.isShowConfirmBox = false;
        if(Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")){
          this.$layer.msg(error.data.msg);
        }else{
          this.$layer.msg("删除收货地址失败，请稍后重试");
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.page-div {
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  .address-item-div {
    width: 95%;
    margin: 0.1rem auto 0 auto;
    border-radius: 0.05rem;
    background-color: #FFFFFF;
    overflow: hidden;
    .addressee-div {
      padding: 0.08rem 0.15rem 0.05rem 0.15rem;
    }
    .addressee-label {
      margin-right: 0.1rem;
      font-weight: bold;
    }
    .other-item-div {
      padding: 0.01rem 0.15rem;
    }
    .btn-div {
      margin-top: 0.1rem;
      padding-bottom: 0.08rem;
    }
    .del-btn {
      margin-right: 0.15rem;
      width: 0.8rem;
      height: 0.25rem;
      border-radius: 0.03rem;
      border: none;
      background-color: #F5F5F5;
    }
    .edit-btn {
      width: 0.8rem;
      height: 0.25rem;
      border-radius: 0.03rem;
      border: none;
      color: #FFFFFF;
      background-color: #DD4F42;
    }
  }
  .empty-data-div {
    width: 100%;
    text-align: center;
    position: fixed;
    top: 30%;
    .empty-data-img-div {
      width: 1.2rem;
      height: 0.8rem;
      margin: 0 auto;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .empty-tips-div {
      margin-top: 0.2rem;
      margin-bottom: 0.5rem;
    }
    .add-btn {
      width: 1.2rem;
      height: 0.3rem;
      border: none;
      border-radius: 0.03rem;
      color: #FFFFFF;
      background-color: #DD4F42;
    }
  }
  .confirm-div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    .confirm-box-div {
      position: fixed;
      top: 35%;
      left: 10%;
      width: 80%;
      border: 1px solid #2c3e50;
      border-radius: 0.05rem;
      padding: 0.1rem 0.05rem;
      text-align: center;
      background-color: #FFFFFF;
      .title-div {
        padding: 0.1rem 0;
        font-weight: bold;
      }
      .content-div {
        padding: 0.1rem 0;
      }
      .btn-div {
        margin-top: 0.3rem;
        input {
          width: 0.8rem;
          height: 0.36rem;
          border-radius: 0.03rem;
          border: none;
        }
        .ok-btn {
          margin-right: 0.1rem;
          color: #FFFFFF;
          background-color: #DD4F42;
        }
        .cancel-btn {
          background-color: #F5F5F5;
        }
      }
    }
  }
}
</style>